import React, { Component } from 'react'
import {
  Badge,
  Box,
  Flex,
  Heading,
  Image,
  Text,

  Stack
} from "@chakra-ui/react"

import { FormattedNumber } from 'react-intl'
import logo from "../../static/svg/logo.svg"
import { Link as GatsbyLink } from 'gatsby'
import { BiCamera } from 'react-icons/bi'

class AdCardPortraitSmaller extends Component {
  displayThumbnail(images, alt, ad) {
    if (!images || !images.url || !images.url.length) {
      return (
        <Flex
          as='picture'
          justify='center'
          align='center'
          p={10}
        >
          <Image
            loading="eager"
            // size='200px'
            w='100%'
            htmlWidth='1'
            htmlHeight='1'
            src={logo}
            alt={ad.refModel.name}
          />
        </Flex>
      )
    }
    else {
      return (
        <Box
          position="relative"
          borderRadius={'4px 4px 0 0'}
        >
          <Text
            as='figcaption'
            position='absolute'
            fontSize='12px'
            background='linear-gradient(0deg, rgba(41,41,41,0) 0%, rgba(25,25,25,0.5368522408963585) 100%)'
            p='.55rem'
            pb='2rem'
            top='0'
            left='0'
            w='100%'
            bg='rgba'
            color='white'
          >
            {ad.refModel.name} / {ad.department}
          </Text>
          <picture>
            {/* <source type='image/avif' srcSet={`${process.env.GATSBY_BASE_IMG_URL}${images.url[0]}?twic=v1/cover=300x300/format=avif/quality=30`} /> */}
            <source type='image/webp' srcSet={`${process.env.GATSBY_BASE_IMG_URL}${images.url[0]}?twic=v1/cover=300x300/format=webp/quality=60`} />
            <Image
              src={
                `${process.env.GATSBY_BASE_IMG_URL}${images.url[0]}?twic=v1/cover=200x200/format=jpeg/quality=40`}
              w='100%'
              h='100%'
              htmlWidth={1}
              htmlHeight={1}
              loading='eager'
              alt={alt}
              title={alt}

              width='1'
              height='1'

              style={{ width: '100%', height: '100%' }}
            />
          </picture>
          <Flex
            position="absolute" bottom="0" left="0" w={'100%'}
            justify="flex-end"
            borderRadius={'4px 0 0 4px'}
            p='3rem .5rem .5rem .5rem'
            alignItems="flex-end"
            background="linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(26, 26, 26, 0.8) 100%)"
            color='white'
          >
            <Text
              as="span"
              fontSize={{ base: '16px', lg: '14px' }}
              color="white"
              pr={'.5rem'}
            >
              {images.url.length}
            </Text>
            <BiCamera />
          </Flex>
        </Box>

      );
    }

  }
  render() {
    const { name, price, to, region, images, isNavigable, ad, index } = this.props;
    return (
      <Box
        style={{
          scrollSnapAlign: 'center'
        }}

        mr={2}

        w={{ base: 'xs', lg: '2xs' }}
        flexShrink={0}
        cursor="pointer"
        as={isNavigable ? GatsbyLink : "div"}
        display="block"
        p={{ base: 0, lg: 0 }}
        background="white"
        shadow="xs"
        borderRadius={"sm"}
        overflow="hidden"
        _hover={{
          boxShadow: "0 0 0px 2px rgba(0,66,218,.7)"
        }}
        onClick={!isNavigable ? event => {
          this.props.openAdDetail()
          // let relatedLink = event.target.closest('.item-card').querySelector('a');
          // relatedLink.click();
        } : null}
      >
        <Box overflow="hidden">
          {images ?
            this.displayThumbnail(images, name, ad)
            :
            <picture>
              <img loading="lazy" alt={name} src={logo} />
            </picture>
          }
        </Box>
        <Box
          p=".75rem"
        >
          <Heading
            as='p'
            display="block"
            w={'100%'}
            fontWeight="400"
            fontSize={{ base: "md" }}
            // pr="2rem"
            textOverflow="ellipsis"
            isTruncated
          >
            {name}
          </Heading>
          <Stack mt=".5rem" isInline>
            <Badge as="span" colorScheme="green" fontSize="sm">
              {(price > 0) ?
                <FormattedNumber
                  value={this.props.currency ? Math.round(parseInt(price) * parseFloat(this.props.currency.rate)) : price}
                  format={this.props.currency ? this.props.currency.name : 'EUR'}
                  minimumFractionDigits={'0'}
                  maximumFractionDigits={'0'}
                />
                : "-"}
            </Badge>
            <Heading as='p' isTruncated fontSize="13px">{region}</Heading>
          </Stack>
        </Box>
      </Box>
    )
  }
}

export default AdCardPortraitSmaller