import React, { Component } from 'react'
import {
  Badge,
  Box,
  Grid,
  Flex,
  Heading,
  Text,
  Stack,
  Image
} from "@chakra-ui/react"

import { FormattedNumber } from 'react-intl'
import logo from "../../static/svg/logo.svg"
import { Link as GatsbyLink } from 'gatsby'

import { BiCamera } from 'react-icons/bi'
import { AdContext } from './adContext'

const AdCardLandscapeSmaller = ({
  id, name, price, to, region, images, isNavigable, currency, openAdDetail
}) => {


  const adContext = React.useContext(AdContext);
  const setAd = adContext ? adContext.setAd : null;


  const displayThumbnail = (images, alt) => {
    if (!images || !images.url || !images.url.length) {
      return (
        <picture>
          <Image
            loading="lazy"
            size='75px'
            width='75px'
            height='75px'
            src={logo}
            p={3}
            alt={'Wanaboat Logo'}
            bgColor='gray.50'
          />
        </picture>
      )
    }
    else {
      return (
        <Box
          position="relative"
          w="75px"
          h="75px"
          borderRadius={'4px 0 0 4px'}
        >
          <picture >

            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=heif/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=webp'
              }
              type="image/heif"
            />
            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=webp/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=webp'
              }
              type="image/webp"
            />
            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=jpeg/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=jpeg'
              }
              type="image/jpeg"
            />
            <img
              src={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=jpeg/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=jpg'
              }
              width={75}
              height={75}
              loading='lazy'
              alt={alt}
            />
          </picture>
          <Flex
            position="absolute" bottom="0" left="0" w={'100%'}
            justify="flex-end"
            align={'center'}
            borderRadius={'4px 0 0 4px'}
            p={1}
            background="linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(26, 26, 26, 0.8) 100%)"
          >
            <Text
              as="span"
              fontSize="xs"
              color="white"
              pr={1}
            >
              {images.url.length}
            </Text>
            <BiCamera color='white' />
          </Flex>
        </Box>

      );
    }

  }
  return (

    <Grid
      templateColumns={{ base: "75px minmax(0, 1fr)" }}
      gap={4}
      className="item-card"
      cursor={isNavigable ? "pointer" : null}
      // as={isNavigable ? GatsbyLink : "article"}
      // to={`${to}`}
      background="white"
      shadow="xs"
      borderRadius="sm"
      _hover={{
        boxShadow: isNavigable ? "0 0 0px 2px rgba(0,66,218,.7)" : null
      }}
      // onClick={!isNavigable ? event => {
      //   openAdDetail()
      // } : null}
      onClick={
        () => {
          setAd(id)
        }
      }
    >
      <Box overflow="hidden">
        {images ?
          displayThumbnail(images, name)
          :
          <picture>
            <img loading="lazy" m='4rem' src={logo} />
          </picture>
        }
      </Box>
      <Flex
        alignItems={'center'}
        maxW='full'
        minW='0'
      >
        <Box>
          <Heading
            as='p'
            fontWeight="400"
            fontSize="md"
            textOverflow="ellipsis"
            isTruncated
            whiteSpace='normal'
          >
            {name.slice(0, 60)}
            {name.length > 60 ? '...' : ''}
          </Heading>
          <Stack mt=".5rem" isInline>
            <Badge as="span" colorScheme="green" fontSize="sm">
              {(price > 0) ?
                <FormattedNumber
                  value={currency ? Math.round(parseInt(price) * parseFloat(currency.rate)) : price}
                  format={currency ? currency.name : 'EUR'}
                  minimumFractionDigits={'0'}
                  maximumFractionDigits={'0'}
                />
                : "-"}
            </Badge>
            <Heading as='p' isTruncated fontSize="13px">{region}</Heading>
          </Stack>
        </Box>
      </Flex>
    </Grid>
  )

}

export default AdCardLandscapeSmaller